import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {
  State,
  Recipe,
  ReduxAction,
  RecipeList,
  IngredientList,
  ParentIngredientList,
  AuthorList,
  ConstraintList,
  FeaturedRecipeList,
  FeaturedRecipe,
  FeaturedRecipeListRecipe,
} from '../types';

const reducer = (
  state: State,
  action: ReduxAction<
    Recipe | RecipeList | IngredientList | AuthorList | ConstraintList | FeaturedRecipe | FeaturedRecipeList
  >
) => {
  switch (action.type) {
    case 'GET_RECIPE_SUCCESS':
      return Object.assign({}, state, { recipe: action.payload as Recipe });
    case 'UPDATE_RECIPE_SUCCESS':
      return Object.assign({}, state, { recipe: action.payload as Recipe });
    case 'CREATE_RECIPE_SUCCESS':
      return Object.assign({}, state, { recipe: action.payload as Recipe });
    case 'CREATE_RECIPE_ERROR':
      return Object.assign({}, state, { recipe: null, recipeError: action.payload });
    case 'GET_RECIPES_SUCCESS':
      return Object.assign({}, state, { recipes: action.payload as RecipeList });
    case 'CLEAR_RECIPE':
      return Object.assign({}, state, { recipe: null });
    case 'GET_INGREDIENTS_SUCCESS':
      return Object.assign({}, state, { ingredients: action.payload as IngredientList });
    case 'GET_PARENT_INGREDIENTS_SUCCESS':
      return Object.assign({}, state, { parentIngredients: action.payload as ParentIngredientList });
    case 'CLEAR_INGREDIENTS':
      return Object.assign({}, state, { ingredients: action.payload as IngredientList });
    case 'GET_AUTHORS_SUCCESS':
      return Object.assign({}, state, { authors: action.payload as AuthorList });
    case 'GET_CONSTRAINTS_SUCCESS':
      return Object.assign({}, state, { constraints: action.payload as ConstraintList });
    case 'GET_RECIPES_SEARCH_SUCCESS':
      return Object.assign({}, state, { recipes: action.payload as Recipe });
    case 'GET_FEATURED_RECIPES_SUCCESS':
      return Object.assign({}, state, { featuredRecipes: action.payload as FeaturedRecipeList });
    case 'CREATE_FEATURED_RECIPE_SUCCESS':
      return Object.assign({}, state, { featuredRecipe: action.payload as FeaturedRecipeListRecipe });
    case 'UPDATE_ALL_FEATURED_RECIPES_SUCCESS':
      return Object.assign({}, state, { featuredRecipes: action.payload as FeaturedRecipeList });
    case 'UPDATE_FEATURED_RECIPE_STATUS_SUCCESS':
      return Object.assign({}, state, { featuredRecipe: action.payload as FeaturedRecipeListRecipe });
    default:
      return state;
  }
};

const initialState: State = {};

const createStore = () => reduxCreateStore(reducer, initialState, applyMiddleware(thunk));

export default createStore;
