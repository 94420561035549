import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import styled from 'styled-components';

const LayoutWrapper = styled.header`
  background: rgb(255, 199, 0);
  padding: 2%;
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0;
`;

const StyledHeader = styled.h1`
  margin: 0;
`;

const Header = ({ siteTitle }) => (
  <LayoutWrapper>
    <div>
      <StyledHeader>
        <StyledLink to="/">{siteTitle}</StyledLink>
      </StyledHeader>
    </div>
  </LayoutWrapper>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
